var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-flex",
        { staticStyle: { "margin-bottom": "15px" } },
        [
          _c(
            "material-card",
            { attrs: { color: "primary", title: "INFORMACIÓN DEL AFILIADO" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md4: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.documentTypeItems,
                          label: _vm.$t("document-type"),
                          "item-value": "code",
                          "item-text": "name",
                          required: "",
                        },
                        model: {
                          value: _vm.vItems,
                          callback: function ($$v) {
                            _vm.vItems = $$v
                          },
                          expression: "vItems",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md4: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          required: "",
                          label: "Número de identificación",
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.loadData.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.vIdentificacion,
                          callback: function ($$v) {
                            _vm.vIdentificacion = $$v
                          },
                          expression: "vIdentificacion",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.loading,
                            color: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.loadData()
                            },
                          },
                        },
                        [_vm._v("BUSCAR")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "hide-overlay": "", persistent: "", width: "300" },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { color: "primary", dark: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _vm._v(
                            "\n            Cargando Informacion....\n            "
                          ),
                          _c("v-progress-linear", {
                            staticClass: "mb-0",
                            attrs: { indeterminate: "", color: "white" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showData,
                      expression: "showData",
                    },
                  ],
                  attrs: { "justify-center": "" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "scroll",
                          rawName: "v-scroll",
                          value: _vm.onScroll,
                          expression: "onScroll",
                        },
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fab,
                          expression: "fab",
                        },
                      ],
                      staticClass: "margin-right: 36px; margin-bottom: 52px;",
                      attrs: {
                        fab: "",
                        dark: "",
                        fixed: "",
                        bottom: "",
                        right: "",
                        round: "",
                        color: "v-btn-twitter",
                      },
                      on: { click: _vm.toTop },
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-up-bold")])],
                    1
                  ),
                  _c(
                    "v-container",
                    { attrs: { "py-0": "" } },
                    [
                      _c(
                        "material-card",
                        { attrs: { align: "center" } },
                        [
                          _c(
                            "v-layout",
                            { staticClass: "ml-1", attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md2: "" } },
                                [
                                  _c("v-text-field", {
                                    staticStyle: { width: "auto" },
                                    attrs: {
                                      disabled: true,
                                      label: "Tipo identificación",
                                    },
                                    model: {
                                      value: _vm.paciente.tipo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.paciente, "tipo", $$v)
                                      },
                                      expression: "paciente.tipo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md3: "" } },
                                [
                                  _c("v-text-field", {
                                    staticStyle: { width: "auto" },
                                    attrs: {
                                      disabled: true,
                                      label: "Número identificación",
                                    },
                                    model: {
                                      value: _vm.paciente.numero,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.paciente, "numero", $$v)
                                      },
                                      expression: "paciente.numero",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", md5: "" } },
                                [
                                  _c("v-text-field", {
                                    staticStyle: { width: "auto" },
                                    attrs: {
                                      disabled: true,
                                      label: "Afiliado",
                                    },
                                    model: {
                                      value: _vm.paciente.nombre,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.paciente, "nombre", $$v)
                                      },
                                      expression: "paciente.nombre",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      [
                        _c(
                          "v-container",
                          { attrs: { "fill-height": "", justify: "center" } },
                          [
                            _c(
                              "v-container",
                              {
                                staticClass: "shrink",
                                attrs: { "fill-height": "" },
                              },
                              [
                                _c(
                                  "v-expand-transition",
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.collapse,
                                            expression: "collapse",
                                          },
                                        ],
                                        staticClass: "mx-auto",
                                        staticStyle: {
                                          "margin-top": "15px",
                                          width: "950px",
                                        },
                                        attrs: { height: "65" },
                                      },
                                      [
                                        _c(
                                          "v-dialog",
                                          {
                                            attrs: { width: "480px" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "80%",
                                                          },
                                                          attrs: {
                                                            color: "primary",
                                                            dark: "",
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "Despacho Manual"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                            model: {
                                              value: _vm.dialog,
                                              callback: function ($$v) {
                                                _vm.dialog = $$v
                                              },
                                              expression: "dialog",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c("v-card-title", [
                                                  _c(
                                                    "span",
                                                    { staticClass: "headline" },
                                                    [
                                                      _vm._v(
                                                        "¿Está seguro que desea hacer el despacho manual de las autorizaciones seleccionadas?"
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "v-container",
                                                  [
                                                    _c(
                                                      "v-form",
                                                      {
                                                        ref: "form",
                                                        model: {
                                                          value: _vm.valid,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.valid = $$v
                                                          },
                                                          expression: "valid",
                                                        },
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.documentTypeItems,
                                                            rules:
                                                              _vm.itemRules,
                                                            label:
                                                              _vm.$t(
                                                                "document-type"
                                                              ),
                                                            required: "",
                                                            "item-value":
                                                              "code",
                                                            "item-text": "name",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modal.type,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modal,
                                                                "type",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modal.type",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            rules:
                                                              _vm.nameRules,
                                                            label:
                                                              "Nro. Cedula*",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modal.numero,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modal,
                                                                "numero",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modal.numero",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            rules:
                                                              _vm.nameRules,
                                                            label:
                                                              "Nombre Completo*",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.modal.nombre,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.modal,
                                                                "nombre",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "modal.nombre",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-actions",
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color:
                                                            "error darken-1",
                                                          text: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.dialog = false
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Cancel")]
                                                    ),
                                                    _c("v-spacer"),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color:
                                                            "primary darken-1",
                                                          text: "",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.changeEstadoItem,
                                                        },
                                                      },
                                                      [_vm._v("Aceptar")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      _c(
                        "v-layout",
                        { staticStyle: { "margin-top": "15px" } },
                        [
                          _c(
                            "material-card",
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label:
                                        "Busqueda por Prestacion Autorizada.",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-data-table", {
                                staticClass: "width-change",
                                attrs: {
                                  headers: _vm.hMedicines,
                                  items: _vm.orderDetails,
                                  "no-data-text": _vm.$t("no-data-available"),
                                  pagination: _vm.pagination,
                                  "rows-per-page-text":
                                    _vm.$t("rows-per-page-text"),
                                  "rows-per-page-items":
                                    _vm.globalrowsPerPageItems,
                                  search: _vm.search,
                                  "item-key": "identificador",
                                  expand: "",
                                  "select-all": "",
                                },
                                on: {
                                  "update:pagination": function ($event) {
                                    _vm.pagination = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "headerCell",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          { attrs: { bottom: "" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "caption font-weight-light text-primary salto",
                                                attrs: { slot: "activator" },
                                                slot: "activator",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(props.header.text) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(props.header.text) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "items",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "tr",
                                          {
                                            class: {
                                              controlAutorizations:
                                                props.item.value,
                                              isOrderManual:
                                                props.item.orderManual,
                                            },
                                          },
                                          [
                                            _c(
                                              "td",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    disabled:
                                                      props.item.estado ==
                                                        "EN PROCESO" ||
                                                      props.item.estado ==
                                                        "ANULADA" ||
                                                      props.item.estado ==
                                                        "ENTREGADA" ||
                                                      props.item.value == false
                                                        ? true
                                                        : false,
                                                    indeterminate:
                                                      props.indeterminate,
                                                    "hide-details": "",
                                                    primary: "",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.toggleAll.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: props.selected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        props,
                                                        "selected",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "props.selected",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showDetails(
                                                          props
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-arrow-right")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "datatable-cell-wrapper salto div-title",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.consecutivo
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "datatable-cell-wrapper div-title",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.fecha)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "datatable-cell-wrapper salto div-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.prestacion
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "datatable-cell-wrapper salto div-title",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.dosis)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "datatable-cell-wrapper salto div-title",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.diagnostico
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-left" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "datatable-cell-wrapper salto div-title",
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.cantidad
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-left" },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        props.item.tooltipState,
                                                      right: "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "datatable-cell-wrapper div-title",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "-23px",
                                                                      },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      props.item
                                                                        .estado
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.mensaje
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("td", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "datatable-cell-wrapper div-title salto",
                                                  staticStyle: {
                                                    "margin-left": "-23px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.mipres)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "150px" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "datatable-cell-wrapper salto div-title",
                                                    staticStyle: {
                                                      "margin-left": "-23px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.Prestador
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "expand",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "table",
                                          {
                                            attrs: {
                                              border: "1",
                                              width: "100%",
                                              heigth: "100%",
                                            },
                                          },
                                          [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                  attrs: {
                                                    colspan: "3",
                                                    align: "center",
                                                  },
                                                },
                                                [_vm._v("ORDEN DE COBRO")]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c("td", {
                                                attrs: { width: "30%" },
                                              }),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        IPS Genera: " +
                                                      _vm._s(
                                                        _vm.datapatient[0]
                                                          .ipsGenerada
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                        Fecha de Expedición: " +
                                                      _vm._s(
                                                        _vm.datapatient[0]
                                                          .fecha_expedicion
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                        Tipo de Plan: " +
                                                      _vm._s(
                                                        _vm.datapatient[0].plan
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "Origen del Servicio: CTC AMBULATORIO NO PRIORIZADO ÚNICA\n                        "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                        Tipo de Evento: " +
                                                      _vm._s(
                                                        _vm.datapatient[0]
                                                          .tipo_evento
                                                      ) +
                                                      "\n                        "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                        Recobro: " +
                                                      _vm._s(
                                                        _vm.datapatient[0].plan
                                                      ) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Orden No.: " +
                                                      _vm._s(
                                                        _vm.datapatient[0]
                                                          .orden_No
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                  attrs: {
                                                    colspan: "2",
                                                    align: "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "INFORMACIÓN DEL AFILIADO"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "CC " +
                                                    _vm._s(
                                                      _vm.datapatient[0].numero
                                                    )
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(_vm.paciente.nombre)
                                                ),
                                              ]),
                                              _c(
                                                "td",
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.datapatient[0]
                                                            .clasificacion
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]),
                                                  _c("v-spacer"),
                                                  props.item.orderManual !== 1
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                          Edad: " +
                                                            _vm._s(
                                                              _vm.datapatient[0]
                                                                .edad
                                                            ) +
                                                            " años\n                        "
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                props.item.orderManual !== 1
                                                  ? _c("div", [
                                                      _vm._v(
                                                        "Fecha N: " +
                                                          _vm._s(
                                                            _vm.datapatient[0]
                                                              .fecha_nacimiento
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.datapatient[0]
                                                      .ips_nombre
                                                  )
                                                ),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "Tel: " +
                                                    _vm._s(
                                                      _vm.datapatient[0]
                                                        .telefono
                                                    )
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  "Correo: " +
                                                    _vm._s(
                                                      _vm.datapatient[0].correo
                                                    )
                                                ),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                  attrs: {
                                                    colspan: "3",
                                                    align: "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "INFORMACIÓN DEL COBRO"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "3" } },
                                                [
                                                  _vm._v(
                                                    "Grupo de Ingresos: " +
                                                      _vm._s(
                                                        _vm.datapatient[0].grupo
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "3" } },
                                                [
                                                  _vm._v(
                                                    "Tipo de Cobro: " +
                                                      _vm._s(
                                                        _vm.datapatient[0]
                                                          .tipo_cobro
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v("Porcentaje de Copago:"),
                                              ]),
                                              _vm.datapatient[0].tipo_cobro ===
                                                "EXENTO" &&
                                              _vm.datapatient[0].valor === 0
                                                ? _c("td", [
                                                    _vm._v(
                                                      "Valor: " +
                                                        _vm._s(
                                                          _vm._f("formatMoney")(
                                                            _vm.datapatient[0]
                                                              .valor
                                                          )
                                                        )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.datapatient[0].tipo_cobro ===
                                                "EXENTO ATEL" &&
                                              _vm.datapatient[0].valor === 0
                                                ? _c("td", [
                                                    _vm._v(
                                                      "Valor: " +
                                                        _vm._s(
                                                          _vm._f("formatMoney")(
                                                            _vm.datapatient[0]
                                                              .valor
                                                          )
                                                        )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.datapatient[0].tipo_cobro ===
                                                "CUOTA DE RECUPERACION" &&
                                              _vm.datapatient[0].valor === 0
                                                ? _c("td", [
                                                    _vm._v(
                                                      "Valor: 10% del valor deL medicamento"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.datapatient[0].valor !== 0
                                                ? _c("td", [
                                                    _vm._v(
                                                      "Valor: " +
                                                        _vm._s(
                                                          _vm._f("formatMoney")(
                                                            _vm.datapatient[0]
                                                              .valor
                                                          )
                                                        )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _c("td", [
                                                _vm._v("Tope Máximo:"),
                                              ]),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                  attrs: {
                                                    colspan: "3",
                                                    align: "left",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "MEDICAMENTOS AUTORIZADOS"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "3" } },
                                                [
                                                  [
                                                    _c("v-data-table", {
                                                      staticClass:
                                                        "elevation-1",
                                                      attrs: {
                                                        headers:
                                                          _vm.hTratamiento,
                                                        items: _vm.itratamiento,
                                                        "disable-pagination":
                                                          "",
                                                        "hide-default-footer":
                                                          "",
                                                        "hide-actions": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "headerCell",
                                                            fn: function ({
                                                              header,
                                                            }) {
                                                              return [
                                                                _c("span", {
                                                                  staticClass:
                                                                    "caption font-weight-light text-primary salto",
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        header.text
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: "items",
                                                            fn: function (
                                                              props
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "tr",
                                                                  {
                                                                    staticClass:
                                                                      "row",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-xs-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .cod_mec
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-xs-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .mdAutorizados
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-xs-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .presentacion
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-xs-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .dosisTabla
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-xs-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .cod_Diagnostico
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-xs-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .cantidad
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                  ],
                                                ],
                                                2
                                              ),
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "3" } },
                                                [
                                                  _c("br"),
                                                  _c("p", [
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                          Fecha de vencimiento: " +
                                                        _vm._s(
                                                          _vm.datapatient[0]
                                                            .fechVencimiento
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "\n                          Mipres: " +
                                                        _vm._s(
                                                          _vm.datapatient[0]
                                                            .mipres
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.selected,
                                  callback: function ($$v) {
                                    _vm.selected = $$v
                                  },
                                  expression: "selected",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }