import activeAuthorizationsList from './activeAuthorizationsList/activeAuthorizationsList.vue'
import orderDetailList from '../orderDetailList'
import medicinesList from '../medicineList.vue'
import detailAutorization from './detail-autorization/DetailAutorization.vue'
import medicationTreatment from './medications-treatment/MedicationTreatment.vue'
import OrderServices from '../../services/orders/OrderService'
import despachoManualServices from '../../services/despacho_manual/despachoManualService'
import providerService from '../../services/provider/ProviderService'
import { Money } from 'v-money'
import axios from 'axios'

var moment = require('moment')

export default {
    components: {
        activeAuthorizationsList,
        orderDetailList,
        medicinesList,
        detailAutorization,
        medicationTreatment,
        Money
    },
    data: () => ({
        showData: false,
        hideDetail: false,
        url: process.env.VUE_APP_API_SCGO,
        loading: false,
        dialog: false,
        expanded: [],
        collapse: false,
        pagination: {},
        selectedItem: {},
        ordersMedicine: [],
        paciente: [{
            nombre: '',
            numero: 0,
            tipo: ''
        }],
        datapatient: [],
        orderDetails: [],
        checkedCategories: [],
        selected: [],
        search: '',
        modal: {
            type: '',
            numero: 0,
            nombre: ''
        },
        colorFechaItem: false,
        hMedicines: [{
            text: '',
            sortable: false,
            value: '',
            align: 'center',
            width: '40px'
        },
        {
            text: 'Consecutivo Autorizacón',
            sortable: false,
            value: 'consecutivo',
            align: 'center',
            width: 'auto'
        },
        {
            text: 'Fecha (aaaa/mm/dd)',
            sortable: false,
            value: 'fecha',
            align: 'center',
            width: '11%'

        },
        {
            text: 'Prestación Autorizada',
            sortable: false,
            value: 'prestacion',
            align: 'center',
            width: 'auto'
        },
        {
            text: 'Dosis',
            sortable: false,
            value: 'dosis',
            align: 'center',
            width: '10%'
        },
        {
            text: 'Diagnóstico',
            sortable: false,
            value: 'diagnostico',
            align: 'center',
            width: 'auto'
        },
        {
            text: 'Cant',
            sortable: false,
            value: 'cantidad',
            align: 'center',
            width: 'auto'

        },
        {
            text: 'Estado',
            sortable: false,
            value: 'estado',
            width: 'auto'

        },
        {
            text: 'Codigo Mipres',
            sortable: false,
            value: 'mipres',
            width: 'auto'

        },
        {
            text: 'Prestador',
            sortable: false,
            value: 'Prestador',
            width: 'auto'
        }
        ],
        hTratamiento: [{
            text: 'Código Medicamento',
            sortable: false,
            value: 'cod_mec'
        },
        {
            text: 'Medicamentos Autorizados',
            sortable: false,
            value: 'mdAutorizados'
        },
        {
            text: 'Presentación',
            sortable: false,
            value: 'presentacion'
        },
        {
            text: 'Dosis',
            sortable: false,
            value: 'dosis'
        },
        {
            text: 'Código Diagnóstico',
            sortable: false,
            value: 'cod_Diagnostico'
        },
        {
            text: 'Cantidad',
            sortable: false,
            value: 'cantidad'
        }
        ],
        status_sms: '',
        itratamiento: [],
        totalAuthorizations: 0,
        vItems: '',
        vIdentificacion: '',
        fab: false,
        authorizationsLoaded: false,
        rowsPerPageItems: [5, 10, 15, 20],
        itemsData: [],
        tooltipState: false,
        documentTypeItems: [],
        valid: false,
        itemRules: [(v) => !!v || 'Item is required'],
        nameRules: [
            (v) => !!v || 'Name is required'
        ],
        emailRules: [
            (v) => !!v || 'E-mail is required'
        ],
        pDosis: '',
        globalTime: '',
        expand: false
    }),

    created() {
        this.getProviders()
        this.getIdentificationType()

        /* this.loadData() */
    },

    mounted() {
        this.loadData()
    },

    beforeDestroy() {
        clearInterval(this.globalTime)
    },

    methods: {
        getProviders() {
            providerService.getProviders().then(response => {
                this.itemsData = response.data
            })
        },
        getIdentificationType() {
            OrderServices
                .getTypeIdentification()
                .then(response => {
                    this.documentTypeItems = response.data
                })
        },

        handleOrder(order, iOrden) {
            this.hideDetail = true

            this.paciente.nombre = iOrden.paciente.nombre.primerApellido + ' ' + iOrden.paciente.nombre.segundoApellido + ' ' + iOrden.paciente.nombre.segundo + ' ' + iOrden.paciente.nombre.primero
            this.paciente.tipo = iOrden.paciente.documento.tipo
            this.paciente.numero = iOrden.paciente.documento.numero

            order.forEach((tems) => {
                var nombrePrestador = ''
                this.itemsData.filter(data => {
                    if (data.fiscalNumber === tems.provider) {
                        nombrePrestador = data.name
                    }
                })

                let payload = JSON.parse(tems.payload)
                let fechServicio = payload.formula.fechaServicio.split('T')
                let fechVencimiento = payload.formula.fechaVencimiento === undefined ? null : payload.formula.fechaVencimiento.split('T')
                let valid = fechVencimiento === null ? false : this.validarFecha(moment(fechVencimiento[0]))
                var status = tems.status
                this.status_sms = ''
                switch (status) {
                    case 'N':
                        status = 'GENERADA'
                        this.colorFechaItem = valid
                        break
                    case 'P':
                        status = 'EN PROCESO'
                        this.status_sms = 'Agendado por Domicilio.'
                        this.colorFechaItem = false
                        break
                    case 'D':
                        status = 'EN PROCESO'
                        this.status_sms = 'Despacho Manual.'
                        this.colorFechaItem = false
                        break
                    case 'A':
                        status = 'ANULADA'
                        this.colorFechaItem = false
                        break
                    case 'E':
                        status = 'ENTREGADA'
                        this.colorFechaItem = false
                        break
                    case 'X':
                        status = 'ENTREGADA PARCIAL'
                        this.colorFechaItem = false
                        break
                    default:
                        break
                }

                let arrayTratamiento = payload.formula.tratamiento
                for (let i = 0; i < arrayTratamiento.length; i++) {
                    this.pDosis = ''
                    if (payload.formula.tratamiento[i].dosis.cantidad === 0) {
                        this.pDosis = '-'
                    } else {
                        this.pDosis = `${payload.formula.tratamiento[i].dosis.cantidad} ${payload.formula.tratamiento[i].dosis.unidad.nombre} CADA ${payload.formula.tratamiento[i].dosis.periodo.cantidad} ${payload.formula.tratamiento[i].dosis.periodo.unidad.nombre} POR ${payload.formula.tratamiento[i].dosis.duracion.cantidad} ${payload.formula.tratamiento[i].dosis.duracion.unidad.nombre}`
                    }
                    this.orderDetails.push({
                        identificador: payload.formula.numero + i,
                        id: tems.id,
                        consecutivo: payload.formula.numero,
                        fecha: fechServicio[0],
                        prestacion: `${payload.formula.tratamiento[i].producto.mapis} - ${payload.formula.tratamiento[i].producto.descripcion} ${payload.formula.tratamiento[i].producto.concentracion}`,
                        dosis: this.pDosis,
                        diagnostico: `${payload.formula.diagnostico.codigo} - ${payload.formula.diagnostico.descripcion}`,
                        cantidad: payload.formula.tratamiento[i].cantidad,
                        estado: status,
                        Prestador: `${tems.provider} - ${nombrePrestador}`,
                        mipres: payload.formula.mipres,
                        value: this.colorFechaItem,
                        tooltipState: !(tems.status === 'P' || tems.status === 'D'),
                        mensaje: this.status_sms,
                        orderManual: tems.isOrderManual
                    })
                }
            })

            this.authorizationsLoaded = true
            this.showData = true
            this.collapse = false
            // this.checkedCategories = []
            return true
        },
        handlerOrderSinData(orderData) {
            orderData.forEach((tems) => {
                var nombrePrestador = ''
                this.itemsData.filter(data => {
                    if (data.fiscalNumber === tems.provider) {
                        nombrePrestador = data.name
                    }
                })

                let payload = JSON.parse(tems.payload)
                var fechServicio = payload.formula.fechaServicio.split('T')
                var fechVencimiento = payload.formula.fechaVencimiento.split('T')
                var valid = this.validarFecha(moment(fechVencimiento[0]).format('DD/MM/YYYY'))
                var status = tems.status
                this.status_sms = ''
                switch (status) {
                    case 'N':
                        status = 'GENERADA'
                        this.colorFechaItem = valid
                        break
                    case 'P':
                        status = 'EN PROCESO'
                        this.status_sms = 'Agendado por Domicilio.'
                        this.colorFechaItem = false
                        break
                    case 'D':
                        status = 'EN PROCESO'
                        this.status_sms = 'Despacho Manual.'
                        this.colorFechaItem = false
                        break
                    case 'A':
                        status = 'ANULADA'
                        this.colorFechaItem = false
                        break
                    case 'E':
                        status = 'ENTREGADA'
                        this.colorFechaItem = false
                        break
                    case 'X':
                        status = 'ENTREGADA PARCIAL'
                        this.colorFechaItem = false
                        break
                    default:
                        break
                }

                this.orderDetails.push({
                    id: tems.id,
                    consecutivo: payload.formula.numero,
                    fecha: moment(fechServicio[0]).format('L'),
                    prestacion: `${payload.formula.tratamiento[0].producto.mapis} - ${payload.formula.tratamiento[0].producto.descripcion}`,
                    diagnostico: `${payload.formula.diagnostico.codigo} - ${payload.formula.diagnostico.descripcion}`,
                    estado: status,
                    Prestador: `${tems.provider} - ${nombrePrestador}`,
                    mipres: payload.formula.mipres,
                    value: this.colorFechaItem,
                    tooltipState: !(tems.status === 'P' || tems.status === 'D'),
                    mensaje: this.status_sms
                })
            })

            this.authorizationsLoaded = true
            this.showData = true
            this.collapse = false
            // this.checkedCategories = []
            return true
        },
        loadData() {
            if (this.vIdentificacion === '' && this.vItems === '') {
                return
            }

            this.modal.number = ''
            this.modal.nombre = ''
            this.loading = true
            this.orderDetails = []
            const { page, rowsPerPage } = this.pagination
            let user = JSON.parse(localStorage.getItem('user'))
            var provider = ''
            this.itemsData.filter(item => {
                if (item.id === user.providerId) {
                    provider = item.fiscalNumber
                }
            })
            OrderServices
                .getAuthorizations(this.vItems, this.vIdentificacion, page - 1, rowsPerPage)
                .then(data => {
                    this.showData = !this.showData
                    this.loading = false
                    this.selectedItem = data.data.msg[0]
                    const orden = data;
                    this.authorizationsLoaded = this.handleOrder(orden.data.msg[0], JSON.parse(orden.data.msg[0][0].payload))
                    if (this.collapse === true) this.collapse = true
                }).catch(() => {
                    this.showData = false
                    this.loading = false
                    this.$notify({
                        group: 'app',
                        type: 'error',
                        text: 'No se han encontrado autorizaciones para el paciente especificado.'
                    })
                })

            /* clearInterval(this.globalTime)
    
             this.globalTime = setInterval(() => {
                 let longOrderDetails = this.orderDetails.length
    
                 OrderServices
                     .getAuthorizations(this.vItems, this.vIdentificacion, page - 1, rowsPerPage, provider)
                     .then(data => {
                         this.showData = true
                         this.loading = false
                         this.selectedItem = data.data.msg[0]
                         this.authorizationsLoaded = this.handleOrder(data.data.msg[0], JSON.parse(data.data.msg[0][0].payload))
                         this.orderDetails.splice(0, longOrderDetails)
                     }).catch(() => {
                         this.showData = false
                         this.loading = false
                         this.$notify({
                             group: 'app',
                             type: 'error',
                             text: 'No se han encontrado autorizaciones para el paciente especificado.'
                         })
                         clearInterval(this.globalTime)
                     })
             }, 50000) */
            // if (this.vIdentificacion === '' && this.vItems === ''){
            //     OrderServices
            //         .getAuthorizations('null', 'null', page - 1 , rowsPerPage, provider)
            //         .then(data => {
            //             this.showData = !this.showData
            //             this.loading = false
            //             this.selectedItem = data.data.msg[0]
            //             this.authorizationsLoaded = this.handlerOrderSinData(data.data.msg[0])
            //             this.totalAuthorizations = data.data.msg[1]
            //         }).catch(erro => {
            //             this.showData = false
            //             this.loading = false
            //             this.$notify({
            //                 group: 'app',
            //                 type: 'error',
            //                 text: 'No se han encontrado autorizaciones para el paciente especificado.'
            //             })
            //         })

            // }else{
            //     OrderServices
            //         .getAuthorizations(this.vItems, this.vIdentificacion, page - 1, rowsPerPage, provider)
            //         .then(data => {
            //             this.showData = !this.showData
            //             this.loading = false
            //             this.selectedItem = data.data.msg[0]
            //             this.authorizationsLoaded = this.handleOrder(data.data.msg[0], JSON.parse(data.data.msg[0][0].payload))
            //             this.totalAuthorizations = data.data.msg[1]
            //         }).catch(erro => {
            //             this.showData = false
            //             this.loading = false
            //             this.$notify({
            //                 group: 'app',
            //                 type: 'error',
            //                 text: 'No se han encontrado autorizaciones para el paciente especificado.'
            //             })
            //         })
            // }
        },
        closeOrder() {
            this.dialog = false
            this.showData = false
        },
        saveScheduling() {
            this.showData = false
        },
        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        },
        toTop() {
            this.$vuetify.goTo(0)
        },
        customFilter(items, search, filter) {
            search = search.toString().toLowerCase()
            return items.filter(row => filter(row['prestacion'], search))
        },

        pushTableMedicim(data, e) {
            let payload = JSON.parse(data.payload)
            const fech = payload.paciente.fechaNacimiento.split('T')
            var fecExpedicion = payload.formula.fechaServicio.split('T')
            var fecExpedicionFormat = fecExpedicion[0]
            var fechaNac = moment(fech[0])
            let fechaActual = moment(Date.now())
            let edad = fechaActual.diff(fechaNac, 'years')
            let fechVencimiento = payload.formula.fechaVencimiento.split('T')
            let fecVencimiento = fechVencimiento[0]
            let mipres = payload.formula.mipres
            let dosis = this.pDosis

            this.datapatient.push({
                id: data.id,
                ipsGenerada: payload.aseguradora.sede.nombre,
                recobro: payload.formula.servicio.nombre,
                tipo_evento: payload.formula.servicio.nombre,
                fecha_expedicion: fecExpedicionFormat,
                orden_No: payload.formula.autorizacion,
                numero: payload.paciente.documento.numero,
                tipo: payload.paciente.documento.tipo,
                fecha_nacimiento: moment(fechaNac).format('L'),
                edad: edad,
                plan: payload.aseguradora.plan.nombre,
                clasificacion: payload.paciente.clasificacion.nombre,
                ips_nombre: payload.paciente.ips.nombre,
                correo: payload.paciente.correoElectronico,
                telefono: payload.paciente.telefono,
                celular: payload.paciente.telefono,
                aseguradora_nit: payload.aseguradora.nit,
                aseguradora_nombre: payload.aseguradora.nombre,
                grupo: payload.formula.categoria.sigla,
                tipo_cobro: payload.formula.cobro.nombre,
                valor: payload.formula.cobro.valor,
                fechVencimiento: fecVencimiento,
                mipres: mipres,
                dosisTabla: dosis
            })

            let arrayTratamiento = payload.formula.tratamiento
            for (let i = 0; i < arrayTratamiento.length; i++) {
                dosis = ''
                if (payload.formula.tratamiento[i].dosis.cantidad === 0) {
                    dosis = '-'
                } else {
                    dosis = `${payload.formula.tratamiento[i].dosis.cantidad} ${payload.formula.tratamiento[i].dosis.unidad.nombre} CADA ${payload.formula.tratamiento[i].dosis.periodo.cantidad} ${payload.formula.tratamiento[i].dosis.periodo.unidad.nombre} POR ${payload.formula.tratamiento[i].dosis.duracion.cantidad} ${payload.formula.tratamiento[i].dosis.duracion.unidad.nombre}`
                }
                this.itratamiento.push({
                    cod_mec: payload.formula.tratamiento[i].producto.mapis,
                    mdAutorizados: payload.formula.tratamiento[i].producto.descripcion,
                    presentacion: payload.formula.tratamiento[i].producto.concentracion,
                    dosisTabla: dosis,
                    cod_Diagnostico: `${payload.formula.diagnostico.codigo} - ${payload.formula.diagnostico.descripcion}`,
                    cantidad: payload.formula.tratamiento[i].cantidad
                })
            }
        },
        validarFecha(date) {
            let response = false
            let today = new Date()

            if (moment(date).isSameOrAfter(moment().format('YYYY-MM-DD'), 'days')) {
                response = true
            } else {
                response = false
            }

            return response
        },
        showDetails(e) {
            this.clearArray()
            this.selectedItem.filter(item => {
                if (item.id === e.item.id) {
                    this.pushTableMedicim(item, e)
                    e.expanded = !e.expanded
                }
            })
        },
        toggleAll() {
            //  if (this.selected.length === 0) {
            //      this.selected = []
            //      this.collapse = !this.collapse
            //  } else {
            //      this.selected = this.items.slice()
            //  }
            if (this.collapse === false) { this.collapse = !this.collapse }
            //clearInterval(this.globalTime)
        },
        changeEstadoItem() {
            let object = []

            if (!this.$refs.form.validate()) {
                return
            }

            this.selected.forEach(check => {
                this.selectedItem.filter(item => {
                    if (check.id === item.id) {
                        object.push({
                            id: item.id,
                            identificationType: item.identificationType,
                            identification: item.identification,
                            provider: item.provider,
                            orderNumber: item.orderNumber,
                            status: 'D',
                            payload: item.payload,
                            creationDate: new Date(),
                            lastUpdate: new Date(),
                            TypeIdentification: this.modal.type,
                            IdentNumber: this.modal.numero,
                            name: this.modal.nombre
                        })

                        despachoManualServices
                            .saveDespacho(object)
                            .then(result => {
                                if (result.data === 'Saved Despacho Manual') {
                                    axios
                                        .post(`${this.url}/api/scgo/v1/update-status-scgo?id=${check.id}`, {
                                            identificationType: item.identificationType,
                                            identification: item.identification,
                                            provider: item.provider,
                                            orderNumber: item.orderNumber,
                                            status: 'D',
                                            payload: item.payload,
                                            creationDate: new Date(),
                                            lastUpdate: new Date()
                                        })
                                        .then(result => {
                                            if (result.data === 'update order sucessfull') {
                                                this.loadData()
                                            }
                                        })
                                        .catch(erro => {
                                            console.error(erro)
                                            this.$notify({
                                                group: 'app',
                                                type: 'error',
                                                text: 'Error en el Servicio actualizar estado SCGO, consultar con Sistemas.'
                                            })
                                        })
                                }
                            })
                            .catch(erro => {
                                console.error(erro)
                                this.$notify({
                                    group: 'app',
                                    type: 'error',
                                    text: 'Error en el Servicio Guardar Despacho Manual, consultar con Sistemas.'
                                })
                            })
                    }
                })
            })
            this.dialog = false
            this.collapse = false
            this.selected = []
        },
        clearArray() {
            this.datapatient = []
            this.ordersMedicine = []
            this.itratamiento = []
        }
    }

}
