import ApiService from '../ApiService';

const orderService = {
    getAuthorizations(type, idNumber, page, perPage) {
        type = type || '';
        idNumber = idNumber || '';
        return ApiService.get(`/orders/authorizationsSCGO?identificationType=${type}&identification=${idNumber}&page=${page}&perPage=${perPage}`);
    },

    getTypeIdentification(){
        return ApiService.get(`/identification-type`)
    }
}

export default orderService;