import ApiService from '../ApiService'

const despachoManualService = {

    saveDespacho (body) {
        // let data = JSON.stringify(body)
        return ApiService.post('/despacho-manual/registerDespacho', body)
    },

    getDespacho () {
        return ApiService.get('/despacho-manual/getDespacho')
    }

}

export default despachoManualService
